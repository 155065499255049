import React from 'react'
import { Link } from 'gatsby'

import imgLogo from '../assets/img/logo.svg'

const Header = ({ siteTitle }) => (
	<div
		style={{
			background: '#fff',
			marginBottom: '1.45rem',
		}}
	>
		<div
			style={{
				margin: '0 auto',
				maxWidth: 960,
				padding: '1.45rem 1.0875rem',
			}}
		>
			<h1 style={{ margin: 0 }}>
				<a href="https://www.reldesk.com/">
					<img src={imgLogo} style={{width: 230, marginBottom: 0}}/>
				</a>
				<Link to="/" className="text-dark float-right">
					<p style={{fontSize: 26, marginLeft: 20, paddingTop: 26, fontWeight: 400}}>{siteTitle}</p>
				</Link>
			</h1> 
		</div>
	</div>
)

export default Header
